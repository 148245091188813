body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-no-repeat bg-center bg-cover bg-fixed bg-black bg-[url('/public/bg.png')];
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.btn-connect {
  @apply font-bold text-xl text-white bg-gradient-to-r from-custom-purple1 to-custom-blue1 rounded border-none;
}
  
  .btn-connect:hover {
    @apply from-custom-purple1/80 to-custom-blue1/80;
  }
  
.btn-transfer {
  @apply font-bold text-lg text-white bg-gradient-to-r from-custom-purple1 to-custom-blue1 rounded border-none;
}
  
  .btn-transfer:hover {
    @apply from-custom-purple1/80 to-custom-blue1/80;
  }
  
  .btn-close {
    @apply bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full border-none;
  }
  
  .btn-close:hover {
    @apply shadow-lg bg-red-600;
  }
  
  .dropdown .dropdown-content.show {
    visibility: visible;
    opacity: 1;
  }